import React, {useState} from "react";
import {NavLink, useLoaderData, Outlet} from "react-router-dom";
import Header from "../Header/Header";
import characters from "../../DATA/characters";

const Content = (props) => {

  

  const [animActive, setAnimActive] = useState("");
  const animPlay = () => {
    setAnimActive("");
    setTimeout(() => {
      setAnimActive(" active");
    }, 200);
  }
    // Map Component
    const ListCharacter = () => {
      const charactersName = characters.map((characterItem) => (
        <li key={characterItem.id}>
            <NavLink onClick={animPlay} to={`/teams/${characterItem.link}`} >{characterItem.name}</NavLink>
        </li>
      ));
      return (
        <ul>
          {charactersName}
        </ul>
      )
    }

  // Reduce
  const initialValue = 0;
  const totalKilledWalking = characters.reduce(
    (accumulator, currentValue) => accumulator + currentValue.totalWalkerKills,
    initialValue
  );
  // Filter
  const totalLiveCharacters = characters.filter(
    (liveStatus) => liveStatus.live === true
  );
  // Filter with function
  const totalDeadCharacters = (() => {
    const totalDead = characters.filter(
      (liveStatus) => liveStatus.live === false
    );
    return totalDead.length;
  })();
  // Map + reverse func
  const ageBigCharacter = (() => {
    const ageerBig = characters.map((itemAgger) => itemAgger.age);
    ageerBig.sort().reverse();
    return ageerBig[0];
  })();
  // Map + sort func
  const ageLittleCharacter = (() => {
    const ageerLittle = characters.map((itemAgger) => itemAgger.age);
    ageerLittle.sort();
    return ageerLittle[0];
  })();
  // Filter with function
  const totalMale = (() => {
    const totalMaleCount = characters.filter(
      (genderStatus) => genderStatus.gender === "male"
    );
    return totalMaleCount.length;
  })();
  // Filter with function
  const totalFamale = (() => {
    const totalFamaleCount = characters.filter(
      (genderStatus) => genderStatus.gender === "famale"
    );
    return totalFamaleCount.length;
  })();
  const totalKilledPeople = characters.reduce(
    (accumulator, currentValue) => accumulator + currentValue.totalPeopleKills,
    initialValue
  );

  // func sort in obj
  const theBestKillerWalk = (() => {
    const sortTotalKilledWalk = characters
      .sort((a, b) => b.totalWalkerKills - a.totalWalkerKills)
      .map((item) => (
        <span key={item.id}>
          {item.name + " (" + item.totalWalkerKills + " - total killed)"}
        </span>
      ));
    return sortTotalKilledWalk[0];
  })();
  // func sort in obj
  const theBestKillerPeople = (() => {
    const sortTotalKilledPeople = characters
      .sort((a, b) => b.totalPeopleKills - a.totalPeopleKills)
      .map((item) => (
        <span key={item.id}>
          {item.name + " (" + item.totalPeopleKills + " - total killed)"}
        </span>
      ));
    return sortTotalKilledPeople[0];
  })();

  // ref points
  const totalFamleNumber = React.useRef();
  const totalMaleNumber = React.useRef();
  const ageLittleCharacterNumber = React.useRef();
  const ageBigCharacterNumber = React.useRef();
  const totalDeadCharactersNumber = React.useRef();
  const totalLiveCharactersNumber = React.useRef();
  const totalKilledPeopleNumber = React.useRef();
  const totalKilledWalkingNumber = React.useRef();
  // number animate count - func
  const loadingNumber = (end, element, sec) => {
    let endCount = end;
    let count = end;
    if(window.location.pathname == "/") {
      if (end > 500) {
        count -= Math.round((end * 5) / 100);
      } else {
        count = 0;
      }
      function addNumber(endCount, element, sec) {
        if (count < endCount) {
          return setTimeout(() => {
            count++;
            element.current.textContent = count;
            addNumber(endCount, element, sec);
          }, sec);
        }
      }
      addNumber(endCount, element, sec);
    }
  };
  // ini numbers animate count
  loadingNumber(totalFamale, totalFamleNumber, 500);
  loadingNumber(totalMale, totalMaleNumber, 500);
  loadingNumber(ageLittleCharacter, ageLittleCharacterNumber, 100);
  loadingNumber(ageBigCharacter, ageBigCharacterNumber, 100);
  loadingNumber(totalDeadCharacters, totalDeadCharactersNumber, 500);
  loadingNumber(totalLiveCharacters.length, totalLiveCharactersNumber, 500);
  loadingNumber(totalKilledPeople, totalKilledPeopleNumber, 10);
  loadingNumber(totalKilledWalking, totalKilledWalkingNumber, 0);


    return (
    <div className="App">
      <Header />
      <div className="containerRow">
        <div className="containerColumnCharacter">
          <h2>Choose a character - {characters.length}</h2>
          <ListCharacter />
        </div>
        <div className="containerColumnInfo">
        <Outlet />
          <h2>General character statistics</h2>
          <div className="totalInfoRow">
            <div className="totalInfoColumn">
              <span ref={totalKilledWalkingNumber}>{totalKilledWalking}</span>
              <p>Walkers killed in total</p>
            </div>
            <div className="totalInfoColumn">
              <span ref={totalKilledPeopleNumber}>{totalKilledPeople}</span>
              <p>Total people killed</p>
            </div>
            <div className="totalInfoColumn">
              <span ref={totalLiveCharactersNumber}>
                {totalLiveCharacters.length}
              </span>
              <p>Total Survivors</p>
            </div>
            <div className="totalInfoColumn">
              <span ref={totalDeadCharactersNumber}>{totalDeadCharacters}</span>
              <p>Total dead</p>
            </div>
            <div className="totalInfoColumn">
              <span ref={ageBigCharacterNumber}>{ageBigCharacter}</span>
              <p>Character's biggest age</p>
            </div>
            <div className="totalInfoColumn">
              <span ref={ageLittleCharacterNumber}>{ageLittleCharacter}</span>
              <p>The youngest character</p>
            </div>
            <div className="totalInfoColumn">
              <span ref={totalMaleNumber}>{totalMale}</span>
              <p>Total male</p>
            </div>
            <div className="totalInfoColumn">
              <span ref={totalFamleNumber}>{totalFamale}</span>
              <p>Total famale</p>
            </div>
          </div>
          <div className="dashboardCharactersRow">
            <h2>Killed the most walkers:</h2>
            {theBestKillerWalk}
          </div>
          <div className="dashboardCharactersRow">
            <h2>Killed the most people:</h2>
            {theBestKillerPeople}
          </div>
        </div>
      </div>
    </div>
      )
};

export default Content;