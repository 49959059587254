import React from "react";
import {useState} from "react";
import {Link, NavLink, useLoaderData} from "react-router-dom";

import Header from "./Header/Header";
import characters from "../DATA/characters";

const Character = (props) => {
  const [animActive, setAnimActive] = useState("");
  const loadingItem = () => {
    window.scrollTo(0,0);
    if(window.innerWidth <= 768) {
      document.querySelector("body").classList.remove("lock");
      document.querySelector(".containerColumnCharacter").classList.remove("active");
      
    }
    setAnimActive("");
    const titleGet = characters.filter(
      (link) => link.link === window.location.pathname.split("/")[2]
    );
    setTimeout(() => {
      setAnimActive("active");
    }, 100);
    return titleGet[0];
  };
  const [character, setCharacter] = useState({});
  const editPageRouter = useLoaderData();

  React.useEffect(()=> {
    setCharacter(loadingItem());
  }, [window.location.pathname]);


    return (
          <div style={{marginBottom:50}}>
          <h2>{character.name}</h2>
          <div className="totalInfoRow">
            <Link className="goBack" to={"/"}>Close the character</Link>
          </div>
          <div className="infoCharacterSingle">
            <div className={"infoCharacterSingle__img " + animActive}>
            <img src={character.img} alt={character.name} />
            </div>
            <div className="infoCharacterSingle__info">
            <ul>
              <li><strong>Age:</strong> {character.age}</li>
              <li><strong>Gender:</strong> {character.gender}</li>
              <li><strong>Live:</strong> {character.live ? "Yes": "No"}</li>
              <li><strong>Favorite weapon:</strong> {character.favoriteWeapon}</li>
              <li><strong>Total People Kills:</strong> {character.totalPeopleKills}</li>
              <li><strong>Total Walker Kills:</strong> {character.totalWalkerKills}</li>
            </ul>
            </div>

          </div>
        </div>


      )
};

export default Character;