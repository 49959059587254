const characters = [
  {
    id: 1,
    name: "Rick Grimes",
    favoriteWeapon: "Colt Python",
    age: 46,
    totalWalkerKills: 1822,
    totalPeopleKills: 45,
    gender: "male",
    live: true,
    link: "rick-grimes",
    img: "https://lumiere-a.akamaihd.net/v1/images/twd_rick_grimes_4_db6c20fa.jpeg?region=99,0,549,550",
  },
  {
    id: 2,
    name: "Shane Walsh",
    favoriteWeapon: "Mossberg 590",
    age: 40,
    totalWalkerKills: 662,
    totalPeopleKills: 5,
    gender: "male",
    live: false,
    link: "shane-walsh",
    img: "https://static1.personality-database.com/profile_images/6dfa692e0f204989a776685324d0444d.png",
  },
  {
    id: 3,
    name: "Daryl Dixon",
    favoriteWeapon: "Crossbow",
    age: 42,
    totalWalkerKills: 5962,
    totalPeopleKills: 50,
    gender: "male",
    live: true,
    link: "daryl-dixon",
    img: "https://upload.wikimedia.org/wikipedia/en/d/db/Daryl_Dixon_Norman_Reedus.png?20190811000210",
  },
  {
    id: 4,
    name: "Glenn Rhee",
    favoriteWeapon: "Glock 17",
    age: 32,
    totalWalkerKills: 742,
    totalPeopleKills: 28,
    gender: "male",
    live: false,
    link: "glenn-rhee",
    img: "https://static.wikia.nocookie.net/walkingdead/images/8/80/Season_seven_glenn_rhee.png",
  },
  {
    id: 5,
    name: "Carol Peletier",
    favoriteWeapon: "Arms M110",
    age: 49,
    totalWalkerKills: 3042,
    totalPeopleKills: 56,
    gender: "famale",
    live: true,
    link: "carol-peletier",
    img: "https://static.wikia.nocookie.net/walkingdead/images/5/5f/Season_eleven_carol_peletier.png",
  },
  {
    id: 6,
    name: "Carl Grimes",
    favoriteWeapon: "Colt Python",
    age: 16,
    totalWalkerKills: 442,
    totalPeopleKills: 3,
    gender: "male",
    live: false,
    link: "carl-grimes",
    img: "https://static.wikia.nocookie.net/walkingdead/images/6/67/Season_eight_carl_grimes.png",
  },
  {
    id: 7,
    name: "Michonne",
    favoriteWeapon: "Katana",
    age: 39,
    totalWalkerKills: 1842,
    totalPeopleKills: 31,
    gender: "famale",
    live: true,
    link: "michonne",
    img: "https://static.wikia.nocookie.net/walkingdead/images/c/c1/Season_ten_michonne.png",
  },
  {
    id: 8,
    name: "Maggie Greene",
    favoriteWeapon: "Smith & Wesson Model 327",
    age: 36,
    totalWalkerKills: 3242,
    totalPeopleKills: 22,
    gender: "famale",
    live: true,
    link: "maggie-greene",
    img: "https://static.wikia.nocookie.net/walkingdead/images/a/ad/Season_eleven_maggie_rhee.png",
  },
  {
    id: 9,
    name: "Rosita Espinosa",
    favoriteWeapon: "M4A1",
    age: 30,
    totalWalkerKills: 2901,
    totalPeopleKills: 19,
    gender: "famale",
    live: false,
    link: "rosita-espinosa",
    img: "https://static.wikia.nocookie.net/walkingdead/images/7/7f/Season_eleven_rosita_espinosa.png",
  },
  {
    id: 10,
    name: "Eugene Porter",
    favoriteWeapon: "Machete",
    age: 32,
    totalWalkerKills: 298,
    totalPeopleKills: 2,
    gender: "male",
    live: true,
    link: "eugene-porter",
    img: "https://static.wikia.nocookie.net/walkingdead/images/7/72/Season_eleven_eugene_porter.png",
  },
  {
    id: 11,
    name: "Negan",
    favoriteWeapon: "Baseball bat",
    age: 44,
    totalWalkerKills: 1898,
    totalPeopleKills: 75,
    gender: "male",
    live: true,
    link: "negan",
    img: "https://static01.nyt.com/images/2016/04/04/arts/04dead3/04dead3-superJumbo.jpg",
  },
  {
    id: 12,
    name: "Father Gabriel Stokes",
    favoriteWeapon: "Machete",
    age: 40,
    totalWalkerKills: 1298,
    totalPeopleKills: 24,
    gender: "male",
    live: true,
    link: "father-gabriel-stokes",
    img: "https://static.wikia.nocookie.net/walkingdead/images/a/a7/Season_eleven_gabriel_stokes.png",
  },
];

export default characters;
