import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Content from "./components/Content/Content";
import Character from "./components/Character";
import "./styles/App.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Content />,
    children: [
      {
        path: "teams/:link",
        element: <Character isActive={""} />,
      },
    ],
  },
  // {
  //   path: "teams/:link",
  //   element: <Character />,
  // },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
