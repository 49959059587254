import React, {useRef} from "react";
import styleClass from "./Header.module.css";

const Header = () => {

    const navMob = () => {
        document.querySelector("body").classList.toggle("lock");
        document.querySelector(".containerColumnCharacter").classList.toggle("active");
    }
    

    return (
        <div className={styleClass.header}>
            <h1>The Walking Dead - Analytics</h1>
            <button onClick={navMob}>Choose a character</button>
        </div>
    )
}

export default Header;